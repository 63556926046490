import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";
import {
  paginationHelper,
  getPaginationResponse,
} from "../utils/paginationHelper";

import store from "@/store";

//delete entity by id
export const deleteGlobalVariable = async (globalVariableId) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  const [selectedGlobalVariable] = await db.global_variables
    .where("_id")
    .equals(globalVariableId)
    .primaryKeys();
  if (selectedGlobalVariable) {
    await db.global_variables.delete(selectedGlobalVariable);
  }
};

//update single entity
export const updateGlobalVariableData = async (globalVariableId, data) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  if(data?.id){
    delete data.id;
  }
  const [selectedGlobalVariable] = await db.global_variables
    .where("_id")
    .equals(globalVariableId)
    .primaryKeys();
  if (selectedGlobalVariable) {
    await db.global_variables.delete(selectedGlobalVariable);
    await addSingleGlobalVariableToLocal(data);
  } else {
    await addSingleGlobalVariableToLocal(data);
  }
};

export const addSingleGlobalVariableToLocal = async (templateData) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  return db.global_variables.add({
    ...templateData,
    ...{ createdAt: new Date().toISOString() },
  });
};

export const fetchGlobalVariables = async (params) => {
  let globalVariables;
  globalVariables = await fetchGlobalVariablesFromLocal(params);
  if (!globalVariables) {
    globalVariables = await fetchGlobalVariablesFromDatabase(params);
  }
  return globalVariables;
};

export const fetchGlobalVariablesFromLocal = async (params) => {
  let collection = db.global_variables;
  if (params?.order_by) {
    collection = collection.orderBy(params.order_by);
    if (params.order_type === "desc") {
      collection = collection.reverse();
    }
  } else {
    collection = collection.orderBy("updated_at");
    collection = collection.reverse();
  }
  const regex = new RegExp(params?.search_string || "", "i");
  if (
    (params?.input_types && params.input_types.length) ||
    params?.input_type ||
    params?.search_string ||
    params?.global_variable_type
  ) {
    if (params?.global_variable_type) {
      let gvType = params.global_variable_type;
      params.input_type = gvType.toUpperCase().trim().split(" ").join("_");
    }
    let is_fixed = false;
    let input_types = [
      ...(params.input_types || []),
      ...(params.input_type ? [params.input_type] : []),
    ];
    if (params?.input_type == "TIME") {
      if (params?.is_fixed == "true") {
        is_fixed = true;
      }
    }
    collection = collection.filter((gVariable) => {
      if (input_types.length) {
        return (
          input_types.includes(gVariable.input_type) &&
          (!params.search_string || regex.test(gVariable.label)) &&
          (!is_fixed || (is_fixed && gVariable.is_fixed == true))
        );
      } else if (params.search_string) {
        return regex.test(gVariable.label);
      }
      return true;
    });
  }

  const count = await collection.count();
  if (params?.limit) {
    params.skip = (params.page - 1) * params.limit;
    collection = paginationHelper(collection, params);
  }
  const results = await collection.toArray();
  if (!results || !results.length) {
    return null;
  }
  return getPaginationResponse({
    page: params?.page || 1,
    count,
    limit: params?.limit || 10,
    skip: params?.skip,
    data: results,
    data_field: "data",
    message: "Templates fetched successfully.",
  });
};
export const fetchGlobalVariablesFromDatabase = async (params) => {
  return postAPICall("GET", `/global-variables`, params);
};
