export const paginationHelper = (collection, params) => {
  if (params.skip) {
    collection = collection.offset(params.skip);
  }

  if (params.limit) {
    collection = collection.limit(params.limit);
  }
  return collection;
};

export const getPaginationResponse = ({
  page = 1,
  count,
  limit = 10,
  skip,
  data,
  data_field = "data",
  message,
}) => {
  const hasMore = getHasMore(skip, data.length, count);
  const totalPages = getTotalNumberOfPages(count, limit);
  return {
    has_more: hasMore,
    total: count,
    page,
    limit: limit || 0,
    total_pages: totalPages,
    success: true,
    message,
    [data_field]: data,
  };
};

const getTotalNumberOfPages = (count, limit) => {
  let totalPages = 0;
  if (count) {
    totalPages = (limit && Math.ceil(count / limit)) || 1;
  }
  return totalPages;
};

const getHasMore = (skip, dataLength, count) => {
  let hasMore = false;
  if (skip !== undefined && skip !== null) {
    hasMore = skip + dataLength < count;
  }
  return hasMore;
};
