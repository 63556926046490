import { db } from "../utils/db";
import { postAPICall } from "../helpers/httpHelper";

import {
  paginationHelper,
  getPaginationResponse,
} from "../utils/paginationHelper";
import store from "@/store";

//delete template by id
export const deleteTemplate = async (templateId) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  const [selectedTemplate] = await db.templates
    .where("_id")
    .equals(templateId)
    .primaryKeys();
  if (selectedTemplate) {
    await db.templates.delete(selectedTemplate);
  }
};

//update single template
export const updateTemplateData = async (templateId, data) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  const [selectedTemplate] = await db.templates
    .where("_id")
    .equals(templateId)
    .primaryKeys();
  if (selectedTemplate) {
    await db.templates.delete(selectedTemplate);
    await addSingleTemplateToLocal(data);
  } else {
    await addSingleTemplateToLocal(data);
  }
};

//Single template operations
export const fetchSingleCompanyTemplate = async (templateId) => {
  try {
    const templateFromDb = await fetchTemplateFromLocal(templateId);
    if (templateFromDb) {
      return templateFromDb;
    } else {
      const response = await postAPICall("GET", `/template/${templateId}`);
      await addSingleTemplateToLocal(response.data);
      return response.data;
    }
  } catch (e) {
    console.log("fetchSingleCompanyTemplate", e);
    return {};
  }
};
export const fetchTemplateFromLocal = async (templateId) => {
  return db.templates.get({ _id: templateId });
};
export const addSingleTemplateToLocal = async (templateData) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  return db.templates.add({
    ...templateData,
    ...{ createdAt: new Date().toISOString() },
  });
};

//Multiple templates operations
export const getAllCompanyTemplatesByPagination = async (params) => {
  let templates;
  templates = await fetchTemplatesFromLocal(params);
  if (!templates) {
    templates = await fetchTemplatesFromDatabase(params);
  }
  return templates;
};
export const fetchTemplatesFromLocal = async (params) => {
  let collection = db.templates;
  if (params.order_by) {
    collection = collection.orderBy(params.order_by);
    if (params.order_type === "desc") {
      collection = collection.reverse();
    }
  } else {
    collection = collection.orderBy("updated_at");
    collection = collection.reverse();
  }
  const standardTemplate = await db.templates.get({ slug: "contactdetails" });
  const regex = new RegExp(params.search_string || "", "i");
  if (
    params.include_templates ||
    (params?.groups && params.groups.length) ||
    params?.group ||
    !params.include_standard
  ) {
    // collection = collection.where("_id").anyOf(params.include_templates);
    let groups = [
      ...(params.groups || []),
      ...(params.group ? [params.group] : []),
    ];
    collection = collection.filter((template) => {
      if (params?.include_templates?.length) {
        return (
          params.include_templates.includes(template._id) &&
          (!params.search_string || regex.test(template.name))
        );
      } else if (groups.length) {
        return (
          groups.some((e) => (template.templateGroups || []).includes(e)) &&
          (!params.search_string || regex.test(template.name))
        );
      } else if (!params.include_standard) {
        return (
          template?._id !== standardTemplate?._id &&
          (!params.search_string || regex.test(template.name))
        );
      }
      return true;
    });
  }

  // if (params.search_string) {
  //   const regex = new RegExp(params.search_string, "i");
  //   collection = collection.filter((template) => regex.test(template.name));
  // }

  const count = await collection.count();
  if (params.limit) {
    params.skip = (params.page - 1) * params.limit;
  }
  collection = paginationHelper(collection, params);
  const results = await collection.toArray();
  if (!results || !results.length) {
    return null;
  }
  return getPaginationResponse({
    page: params.page || 1,
    count,
    limit: params.limit || 10,
    skip: params.skip,
    data: results,
    data_field: "data",
    message: "Templates fetched successfully.",
  });
};
export const fetchTemplatesFromDatabase = async (params) => {
  return postAPICall("GET", `/template/list`, params);
};
